///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/


// Internal variables
$nav-top-font-size: 16px;
$nav-top-font-size--large: 20px;


.nav-top__mailing, .nav-top__login, .nav-top__logged  {
    text-align: center;
}
.nav-top__mailing a, .nav-top__login label {
    @include inuit-font-size($nav-top-font-size);
    font-weight: 600;
    vertical-align: middle;
    @include media-query(desk) {
        @include inuit-font-size($nav-top-font-size--large);
    }
    @include media-query(portable) {
        @include link-quiet();
    }
}
.nav-top__mailing--promo a, .nav-top__login--promo label {
    @include link-quiet();
}
.nav-top__mailing  {
    margin-left: 1%;
    float: left;
}
    .nav-top__mailing  a {
        display: block;
        padding: 0 $inuit-base-spacing-unit $inuit-base-spacing-unit--tiny 0;
        vertical-align: middle;
        @include media-query(desk) {
            @include inuit-font-size($nav-top-font-size--large);
        }
    }

.nav-top__login  {
    float: right;
}
    .nav-top__login  label {
        padding: $inuit-base-spacing-unit--tiny;
        padding-left: 0;
    }
    .nav-top__login  input {
        max-width: 130px;
    }
.nav-top__logged {
    float: right;
}
    .nav-top__logged a {
        display: block;
        padding: $inuit-base-spacing-unit--tiny 0 $inuit-base-spacing-unit--tiny $inuit-base-spacing-unit;
        vertical-align: middle;
        @include media-query(portable) {
            @include link-quiet();
        }
        @include media-query(desk) {
            line-height: $inuit-base-spacing-unit--large;
        }
    }