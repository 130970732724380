///*------------------------------------*\
//    #ICONS
//\*------------------------------------*/

.icon__input--inline:before {
    @extend .icon;
    position: absolute;
    right: .5em;
    bottom: .5em;
    font-size: $inuit-base-line-height;
    z-index: $z-index-icon-input;
    pointer-events: none;
    cursor: pointer;
}

.icon__input {
    position: absolute;
    right: $inuit-base-spacing-unit--small;
    bottom: $inuit-base-spacing-unit--small;
    font-size: $inuit-base-line-height;
    z-index: $z-index-icon-input;
    cursor: pointer;
}

.icon__input ~ .text-input,
.icon__input--inline > .text-input {
    padding-right: $inuit-base-spacing-unit*2;
    border-radius: 20px;
}

.textInput--RightIcon--arrow {
  background:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+PGxpbmVhckdyYWRpZW50IGlkPSdncmFkaWVudCc+PHN0b3Agb2Zmc2V0PScxMCUnIHN0b3AtY29sb3I9JyNGMDAnLz48c3RvcCBvZmZzZXQ9JzkwJScgc3RvcC1jb2xvcj0nI2ZjYycvPiA8L2xpbmVhckdyYWRpZW50PjxyZWN0IGZpbGw9J3VybCgjZ3JhZGllbnQpJyB4PScwJyB5PScwJyB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJy8+PC9zdmc+")
      no-repeat calc(100% - 10px) calc(50% + 2px);
  background-size: 15px 7px;
  background-color: white;
}

i.icon_modal_close {
    color: $color-negative;
    float: right;
    padding: $inuit-base-spacing-unit--small;
    @include attention() {
        cursor: pointer;
        color: #fff;
        background-color: $color-negative;
    }
}

.icon-inline {
    height: .9em;
    margin-top: -4px;
}
.icon_sun {
    width: 16px;
    &:before {
        color: #face1e;
    }
}
.icon_moon {
    width: 14px;
    &:before {
        color: #009bf8;
    }
}


.icon-badge {
    max-height: 1em;
    max-width: 1em;
}