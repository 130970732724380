///*------------------------------------*\
//    #PROMO-BOX
//\*------------------------------------*/

// Internal variable
$promo-box-height: 260px;

.promo-box__grid  {
    @include span(1 of 3);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box__grid_2  {
    @include span(2 of 3);
    @include media-query(lap) {
        @include span(2 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box__grid_3  {
    @include span(3 of 3);
    @include media-query(lap) {
        @include span(2 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box {
    @media screen and (min-width:768px) and (max-width: 1000px) {
        margin-top: 80px !important;
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: $promo-box-height;
    margin-bottom: $wrapper-padding-spacing-unit;
    position: relative;
    text-align: center;
    width: 100%;
    transition: all 0.45s;
}
.promo-box:hover {
    box-shadow: inset 0px -80px 150px -40px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 12px -10px rgba(0,0,0,0.75);
}
.promo-box__title {
    background-color: rgba(black, 0.5);
    padding-left: $inuit-base-spacing-unit--tiny;
    padding-right: $inuit-base-spacing-unit--tiny;
}
.promo-box__subtitle {
    background-color: rgba(black, 0.5);
    bottom: 0;
    padding-left: $inuit-base-spacing-unit--tiny;
    padding-right: $inuit-base-spacing-unit--tiny;
    position: absolute;
    width: 100%;
}
.promo-box__title, .promo-box__subtitle {
    padding: $inuit-base-spacing-unit--tiny 0;
    width: 100%;
    transition: all 0.45s;
}
.promo-box:hover .promo-box__title, .promo-box:hover .promo-box__subtitle {
    background-color: rgba($color-main, 0.8);
}
.promo-box__title *, .promo-box__subtitle * {
    color: white;
    margin-bottom: 0;
    @include font-weight(bold);
}
.promo-box__subtitle * {
    line-height: 1.2;
}






.promo-box__link {
    background-color: rgba(black, 0.65);
    color:#fff;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity 0.45s;
    @include font-size(14px);
    @include font-weight(bold);
    box-shadow: 0px 4px 20px -10px rgba(0,0,0,0.75);
}
.promo-box:hover  .promo-box__link {
    opacity: 1;
}
.promo-box__conditions {
    background-color: rgba(black, 0.65);
    bottom: 0;
    color:#fff;
    cursor: pointer;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small;
    position: absolute;
    width: 100%;
    transition: opacity 0.45s;
    @include font-size(11px);
    box-shadow: 0px -4px 20px -10px rgba(0,0,0,0.75);
}
.promo-box:hover  .promo-box__conditions {
    opacity: 1;
}
.carousel.one{
    display: none;
}
.sp-stack--big,
.btn--slide-scroll {
    display: none;
}