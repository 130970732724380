/*------------------------------------*\
    #FORMS
\*------------------------------------*/


.text-input--underline {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #fff; //$color-form-underline;
    padding-left: 2px;
    @include font-size( 20px);
    font-weight: normal;
    @include attention() {
        color: darken($color-form-underline, 10%);
    }
}

.text-input--no-border {
    background-color: rgba(0,0,0,0) !important;
    border: 0px;
    padding-left: 0;
}

.text-input.is-hightlight {
    border-color: $color-form-hightlight;
    box-shadow: inset 0 0 2px 2px rgba(0,0,0,0.06);
}

.text-input.is-hightlight {
    border-color: $color-form-hightlight;
    box-shadow: inset 0 0 2px 2px rgba(0,0,0,0.06);
}

.text-input.is-readonly {
    background-color: $color-form-readonly;
}
input.text-input {
    height: 36px;
}