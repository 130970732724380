/*------------------------------------*\
    #MARGIN
\*------------------------------------*/

.condensed {
    margin-bottom: $inuit-base-spacing-unit;
    margin-bottom: ($inuit-base-spacing-unit / $inuit-base-font-size) * 0.5rem;
}





.no-margin {
    margin: 0;
}
.no-margin-top {
    margin-top: 0;
}
.no-margin-right {
    margin-right: 0;
}
.no-margin-bottom {
    margin-bottom: 0;
}
.no-margin-left {
    margin-left: 0;
}





.no-padding {
    padding: 0;
}
.no-padding-top {
    padding-top: 0;
}
.no-padding-right {
    padding-right: 0;
}
.no-padding-bottom {
    padding-bottom: 0;
}
.no-padding-left {
    padding-left: 0;
}