                    /*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    overflow-x: hidden;
}
body {
    width: 100%;
      background-color: #f0f0f0;
    @include media-query(palm) {
        overflow-x: hidden;
    }
}
                