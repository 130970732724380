/*------------------------------------*\
    #LABEL
\*------------------------------------*/

label {
    display: block;
    margin-bottom: $inuit-base-spacing-unit--tiny;
}

.label-inuit-inline {
    display: inline-block;
    margin-bottom: 0;
}

.label--error {
    color: $color-label-error;
}