///*------------------------------------*\
//    #SEARCH DOCUMENTATION
//\*------------------------------------*/

.min__col-documentation {
    @include span(2 of 5);
    @include media-query(portable) { 
        @include span(full);
    }
}

.max__col-documentation {
    @include span(3 of 5);
    @include media-query(portable) { 
        @include span(full);
    }
}

.text-input-documentation--underline {
    background-color: rgba(0,0,0,0) !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: $color-form-underline;
    padding-left: 2px;
    @include attention() {
        color: darken($color-form-underline, 10%);
    }
}