@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'icon';
    src:url('../fonts/icon/icon.eot');
    src:url('../fonts/icon/icon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icon/icon.woff') format('woff'),
        url('../fonts/icon/icon.ttf') format('truetype'),
        url('../fonts/icon/icon.svg#codropsicons') format('svg');
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,400,500,700,900&display=swap');
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxMIzIFKw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxEIzIFKw.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxLIzIFKw.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxHIzIFKw.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxGIzIFKw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxIIzI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}
body {
    margin:0;
    padding:0;
    background: none;
    font-family: 'Roboto', sans-serif;
    /*font-family: 'Open Sans Condensed', sans-serif;*/
    /*background:url(/assets/union_representaciones_assets/img/custom/fondo-estre2.jpg) repeat-x #fff;*/
}
a {
    text-decoration:none;
}
.contenedor {width:100%; max-width:1200px; margin:auto;}

.juan_icon {max-height: 75px;}

.azulin { color:#4792cf; text-shadow: 1px 2px 8px #000000;}
.amarillo { color:#fec716;}
.rojo {color:#d11a24;}
.azul {color:#4792cf;}
.right_cont {float: right;}
.contenedor h3 {
    border-bottom: 1px solid;
    color: #3B3F48;
    font-variant: small-caps;
}

.header {
    width:100%;
    position: absolute;
    z-index: 1000;
}

.cabezote {
    width:100%;
    max-width:1400px;
    margin:0 auto;
}
.logo {
    width:30%;
    float:left;
}
.logo img {
    margin-top:10px;
    width:100%;
}
.sellos {
    float: left;
    width: 33%;
    padding-top: 17px;
    padding-left: 1%;
}
.sellos img{
    max-width: 370px;
    width: 100%;
}
.telefono {
    color: #fff;
    float: right;
    width: 18%;
    padding-top: 8px;
    text-shadow: 1px 1px 1px #000;
}
.language {
    width:4%;
    margin-top: 15px;
    float:right;
}
.social_icon a{
    color: #3B3F48;
    font-size: 35px;
}
.telefono h3 span {
    font-size:10px;
}
.telefono h3 {
    line-height:0.5em;
    font-weight: normal;
    color: #fff;
    font-size:1.1em !important;
	text-align:right;
}
.telefono p {
    line-height:18px/*30px*/;
    font-size:13px;
     margin: 0;
	text-align:right;
}
.telefono a {
    line-height:0;
    font-size:13px;
    color:#fff;
	float:right;
}
.language p {
    font-size:18px;
    color:#fff;
    line-height: 0;
    text-align: center;
    margin: -17px 0 0 0;
    width: 31px;
}
.language a:hover span {
    color:#4f4e51;
}
.language span {
    font-size:30px;
    margin: 0 -31px 0 0;
}
a, .link-span { color: #2c6eb0;}
.dispo-flight__filter h2, .dispo-flight__filter h3 {color: #2c6eb0;}
.frame, .frame-single, .frame-pdf, .frame-single-pdf, .resume-search {
    background-color: rgba(255, 255, 255, 0.017);
    border: 0px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 4px 8px #d8d8d8;
}
.search-hotel__col-submit {width: 100%;}
.globo_lg {width: 34px; height: 20px; float: left;}
.dispo-flight__row-title .dispo-flight__row__origin{color: #2c6eb0;}
.dispo-flight__row-title .dispo-flight__row__destination {color: #2c6eb0;}
.dispo-flight__row-title .dispo-flight__row__date {color: #2c6eb0;}
.list-radio, .list-radio--big, .list-checkbox {border: 1px solid #009bf8;}
.list-radio > .list-checked, .list-radio--big > .list-checked, .list-checkbox > .list-checked {background-color: #2c6eb0;}
.nav-option.active:after {background-color: #2c6eb0;}
.modal-header {
    border-bottom: 1px solid #2c6eb0;
    background-color: #2c6eb0;
}
/* SLIDER */
.slider_top {margin: 0 auto 18px;}
.slider_top h2 {color: #fff; font-size: 50px; float: left; display: block; position:relative;}
.slider_top h2 span {color: #fff; font-size: 30px; font-family: 'Open Sans Condensed', sans-serif; font-weight: lighter;}
.slider_top h2 .icon {font-family:'icon';font-size: 80px; float: right; display: block; position:absolute; top:0; right:-90px;}
.slider_top p {font-size: 22px; text-align: right;}
.articulo_inc {width:90%; padding:0 5%;}
.tp-rightarrow .tp-lefttarrow {top:350px !important;}
/*FOOTER*/
.footer {
    background-color: #FFF;
    clear: both;
    margin: 30px auto 0;
    padding: 0;
    width: 100%;
}
.footer_contenedor {
    height: 160px;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}
.column_footer1 {
    float: left;
    width: 30%;
}
.column_footer1 a, .column_footer2 a {
    width: 100%;
}
.column_footer2 {
    float: right;
    width: 40%;
}

.column_contacto{
	max-width:1200px;
	margin:auto;
	width:100%;
	text-align:center;
	}


.column_footer1_contacto{
	 float: left;
    width: 22%;
	text-align:center;
	font-weight: bold;
	margin-right: 3%;

	}

.texto_marca {
	    width: 100%;
    max-width: 1200px;
    float: right;
    font-size: 14px;
    text-align: right;
	color: #1574bb;
}


@media screen and (max-width: 1026px) {

	.column_footer1_contacto{
    width: 22%;
	}


}


@media screen and (max-width: 723px) {

	.column_footer1_contacto{

    width: 100%;
	}

.column_footer1_contacto p {
	width:100%;
	padding: 0px 20%;
}


}




.footer_contenedor a, .footer_contenedor p {
    color: #000;
    float: left;
    font-size: 13px;
    line-height: 1.9em;
    margin: 0;
    text-decoration: none;
	text-align: justify;
    font-weight: normal !important;
	 /*font-family: "Open Sans",sans-serif;*/
}



hr {
  border:none;
  border-bottom:1px solid #a5a5a5;
  margin-bottom: 17px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
}



.copy {
   /* background-color: #3B3F48;*/
    color: #1574bb;
    width: 100%;
}
.copy_contenedor {
    margin: auto;
    max-width: 1218px;
    padding: 5px 30px;
    text-align: right;
    font-size: 12px;
}
a:hover {
    color: #4792cf;
}
.sociales {
    margin: 0 auto;
    max-width: 1218px;
    padding: 30px 20px;
}
.iz { float: left;}
.der { float: left;}
.cont { margin: 5px 0 0 10px;}

/*paquetes*/
.pqts {
    width: 100%;
    margin: auto;
    max-width:1200px;
}
.column1 {
    width: 30%;
}
.column2 {
    width: 63.3333%;
}
.column3 {
    width: 96.6667%;
}
.column1, .column2, .column3 {
    font-family:'icon' !important;
    float: left;
    height: 300px;
    background-color: aqua;
    margin: 20px 1.6666666%;
    position:relative;
    color:#fff;
    font-size: 50px;
    line-height: 21px;
    text-align:center;
    position:relative;
}
.desc_pqts {
    position: absolute;
    right: 0;
    top:0;
    width:60px;
    height:300px;
    background-color: rgba(0, 0, 0, 0.5);
}
.desc_pqts span {
    color: #000;
    display: block;
    font-family: 'Open Sans Condensed', sans-serif, Verdana,Geneva,sans-serif;
    font-size: 15px;
    margin: -21px 0 0 22px;
}
.desc_pqts2 {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width:500px;
    font-family: 'Open Sans Condensed', sans-serif, Verdana,Geneva,sans-serif;
    font-size: 17px;
    line-height: 25px;
    width: 100%;
    height:300px;
    background-color: rgba(0, 0, 0, 0.5);
}
.desc_pqts2 h2, .desc_pqts2 p {margin:10px !important; color:#fff;}
.desc_pqts p{margin: 54px 0;}
.notas {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    font-family: 'Open Sans Condensed', sans-serif, Verdana,Geneva,sans-serif;
    font-size: 25px;
    left: 0;
    line-height: 16px;
    margin: 0;
    padding: 12px 2px;
    position: absolute;
    width: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}
.notas {
    transform: translate3d(0px, 0%, 0px);
    transition: transform 0.35s ease 0,2s;
}
.column1:hover .notas, .column2:hover .notas, .column3:hover .notas {
    transform: translate3d(0px, 0%, 0px) translate3d(0px, -22px, 0px);
}

.detalle_pqts { width:96%; margin:2%;}

/* TABLES */
.features-list table {
    color: #333333;
    text-align: center;
    width: 100%;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
table {
    background-color: rgba(0, 0, 0, 0);
    max-width: 100%;
}
.features-list table th {
    background: none repeat scroll 0 0 #3B3F48;
    border-bottom: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    color: #FFFFFF;
    padding: 5px;
    text-align: center;
}
.custom {
    overflow:auto;
    width:100%;
	text-align:center;
    margin:2%;
}
.cabezote .btn--accent {background-color: rgba(0, 0, 0, 0.2); color:#fec716;}
header h1 {color: #fff;}
.cabezote label {color:#fec716;}

/*Buscador adicionales*/
.link-span.is-active{color:#2c6eb0;}
.afa_search h1 { height: 36px; padding-top: 0px; width: 100%; padding-left: 0px;color: #2c6eb0;font-weight: 900;}
.afa_search .search__btn-group {margin-top: -30px;}
.afa_search .select-group, .afa_search .text-input {background-color: #fff !important; color:#2c6eb0 !important;}
.afa_search .text-input {color:#2c6eb0 !important;border-radius: 20px;}
.afa_search .form-group {margin:0;}
.afa_search .search__link-options {margin-top: 12px;}
.afa_search  {min-height: inherit; width:100%; max-width:1200px; margin:auto; }
.afa_search_image  {background: none;  background-size: cover;}
.tparrows.preview3  {margin-top: -119px !important;}
.telefono h3 {margin: 10px 0 3px;}
.page-head {box-shadow: none !important; margin:0 !important;}
.search__link-add-multi {z-index:1000; background-color: #3B3F48; padding: 10px; position: relative; text-align: justify;}
.search-shared__datepicker-container {z-index:2000 !important;}
.page__body--search .search__engine {background-color: rgba(0, 0, 0, 0);}
.search__option.link-span{color: #2c6eb0;}
.serv_afa .icon {
    background-color: #3B3F48;
    border: 2px solid #CCCCCC;
    border-radius: 100%;
    color: #FFFFFF;
    display: block;
    font-size: 25px;
    height: 48px;
    line-height: 23px;
    margin: 12px auto 0;
    padding: 11px 10px;
    width: 48px;
}
.serv_afa {
    border-bottom: 1px solid;
    font-weight: bold;
    padding: 10px;
    text-align: center;
}
.features-list table tr {
    text-align: left;
}
.intro_resalt {
    border: 4px double #333;
    float: left;
    font-size: 18px;
    font-weight: bold;
    margin: 10px;
    padding: 22px;
}
.search__col-flight-date .icon {color:#fff;}
.select-group select {
    width: calc(100% + 24px) !important;
}
.icon_search_calendar-out:before, .icon_bundle_package:before, .icon_search_close:before, .icon_bundle_flight-hotel:before, .icon_bundle_hotel:before, .icon_bundle_package:before, .icon_contact_address:before, .icon_contact_phone:before, .icon_dart_right:before, .icon_search_calendar:before,  .icon_passenger_man:before, .icon_passenger_child:before, .icon_passenger_baby:before, .icon_chevron_down:before{
    display: none;
}

.js-slideToggle {margin: 0 0 30px 0;}
.center-block {margin-top: 0px;}

.overlay {z-index:1039 !important;}

.text-input, .text-input:hover, .text-input:active, .text-input:focus {
    /*background: none repeat scroll 0 0 #333333  !important;*/
}
.menu_union { width:100%; margin:auto; max-width:100%; z-index:1; position:relative;}
.nav-main{
  width: 70%;
  float: left;
}
.nav-main__grid {
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (max-width: 45.0625em){
  .nav-main__grid {
    min-width: 120px;
    margin: 10px 0px 25px 0px;
    display: inline-block;
    padding: 0px !important;
    border: 0px solid #d4d4d4;
    border-radius: 3px;
  }
  .nav-main {
    width: 100%;
  }
}
.nav-main__item { bord:none !important; border-radius: 0;}

.nav-main__icon--flight{
  background-image: url(/assets/union_representaciones_assets/img/custom/plane.svg)
}
.nav-main__icon--hotel {
  background-image:url(/assets/union_representaciones_assets/img/custom/hotel.svg);
}

.nav-main__icon--disney {
  background-image:url(/assets/union_representaciones_assets/img/custom/disneyland-castle.svg);
}

.nav-main__icon--croise {
  background-image:url(/assets/union_representaciones_assets/img/custom/cruise.svg);
}
.nav-main__icon--package{
  background-image:url(/assets/union_representaciones_assets/img/custom/baggage.svg);
}
.nav-main__icon--experience {
	background-image: url(/assets/union_representaciones_assets/img/custom/tickets.svg);
}
.nav-main__icon--train {
	background-image: url(/assets/union_representaciones_assets/img/custom/train.svg);
}
.nav-main__item { color:#fff;background: none;box-shadow: none;}
.head_union_menu {
  display: flex;
  list-style: none;
  text-align: left;
  padding-left: 0px;
  overflow: auto;
  background: none;
}
.afa_search_image {}
.nav-main__title { color:#2c6eb0;font-weight: 300;}

.nav-main__item:hover, .nav-main__item:active, .nav-main__item:focus {
    position: relative;
    top: -8px;
    box-shadow: none;
}

.label-top {
    font-weight: 300;
}
a, strong, b, dt {
    font-weight: none !important;
}
.promo-banner img{
  margin-top:0px;
  border-radius: 17px;
  width: 100%;
}
.promo-box,.promo-banner {
    background-repeat: no-repeat;
    margin-bottom: 18px;
    text-align: center;
    position: relative;
    margin: 20px 2%;
    width: 95%;
    height: 300px;
    border-radius: 17px;
    background: #f2f2f2;
    background-position: 50% 50%;
    bacgkround-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 10px 15px rgba(1,1,1,0.3);
    transform: scale(0.9);
    transition: 0.35s ease-in-out;
    float: left;
}
.promo-banner{
  height:100%
}
.promo-box h3{
  font-family:'Roboto';
  font-weight: bold;
  font-size: xx-large;
}
.promo-box:hover .promo-box__title, .promo-box:hover .promo-box__subtitle {
    background-color: rgba(0, 0, 0, 0);
}
.promo-box__conditions {
    border-radius: 0 0 15px 15px;
}
.promo-box__link {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  top: inherit;
  width: 100%;
  box-shadow: none;
  position: absolute;
  left: 0;
  bottom: 0;
  font-weight: 100;
  font-size: 1.7em;
  line-height: 1.3;
  padding: 20px;
  color: #fff;
  transition: 0.35s ease-in-out;
}
.promo-box__grid {
    padding-left: 0;
    padding-right: 0;
    margin-left: 30px;
    float: left;
    width: 30%;
    height: auto;
    overflow: hidden;
    align-items: center;
}
.promo-box:hover {
  transform: scale(1);
}
.promo-banner:hover{
  transform:scale(1);
}
.promo-box:hover .promo-box__link {
    opacity: 0;
}
.promo-box:hover:before, .promo-box.active:before {
    opacity: 0;
}
.promo-box:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,1));
    transition: 0.35s ease-in-out;
    content: '';
}
.text-input {
  font-weight: 300;
}
.ui-datepicker-calendar tbody tr td a.ui-state-default {
    color: #7b7b7b;
}
.ui-datepicker-calendar tbody tr td.returnDate, .ui-datepicker-calendar tbody tr td.depDate {
    background-color: #2c6eb0;
}
.ui-datepicker-calendar tbody tr td.depSelect {
    background-color: #2c6eb0;
}
.btn--accent {
    background-color: #2c6eb0;
    border-color: #2c6eb0;
}
.type-main {
    color: #2c6eb0;
}
.btn--secondary {
    color: #2c6eb0;
}
.btn--slim {
  font-weight: 400;
  background-color: #ffffff;
  border-color: #2c6eb0;
}
.btn-group > .btn:not(.btn--inactive), .btn-group > .btn--slim:not(.btn--inactive) {
    background-color: #2c6eb0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn--submit {
  font-weight: 300;
  border-color: transparent;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  border: 2px solid #2c6eb0;
  background-color: #2c6eb0;
  border-radius: 50px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-box-shadow: 0 0 15px #3f51b5;
  box-shadow: 0 0 15px #3f51b5;
}
.btn--submit:hover, .btn--submit:active, .btn--submit:focus {
    background-color: transparent;
    border-color: #2c6eb0;
    color:#2c6eb0;
}
@media screen and (max-width: 1023px) {
    .nav-main__item {
    /*background-color:#0071bb;*/
    border: 2px solid #0071bb00/* $color-nav-main-item; */;
    border-radius: 5px;
    color: #0071bb;
    padding: 0px;
    margin: 0px;
    }
    .nav-main__item img {
    height: 80px;
    }
    .nav-main__title {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 880px) {
    /*body { background: url("../img/fondo.jpg") no-repeat scroll;}*/
    .sellos{display: none;}
    .logo {width: 80%;}
    .telefono {width: 23%;}
    .language {width: 17%;}
    .top_pqts {background-color:#333; background-repeat:repeat-x;}
    .desc_pqts2 {max-width:inherit; height:auto;}
    .column1, .column2 {
        width: 96.6667%;}
}
@media screen and (max-width: 855px) {
    .slider_top p {display:none;}
}
@media screen and (max-width: 800px) {
    body {background: none}
    .afa_search_image  {background: none;  background-size: cover; position: initial;}
	.menu_union { max-width:none;}
}
@media screen and (min-width: 740px) and (max-width: 800px) {
    .slider_top, .spacing_top2 {margin: 0 auto -380px !important;}
    .header { position: relative;}
}
@media screen and (max-width: 740px) {
    .slider_top {display:none;}
    .header {position:inherit;}
}
@media screen and (min-width: 720px) and (max-width: 740px) {
    .slider_top, .spacing_top2 {margin: 0 auto -50px !important;}
}
@media screen and (max-width: 724px) {
    .slider_top{ margin:0; margin-bottom:0;}
    .afa_search  { margin-top:0;}
    .afa_search .wrapper {width:100%;}
    .afa_search h1 {background-color: none; height: 75px; padding-top: 0; background-color: rgba(0, 0, 0, 0); }
}
@media screen and (max-width: 624px) {
    .column_footer1, .column_footer2 {margin-left: 4%;width: 96%;}
    .features-list table {font-size:12px;}
    .telefono a {font-size:14px;}
    .telefono h3 {font-size: 22px;}
    .telefono {width: 28%;}
    .language {width: 22%;}
}
@media screen and (max-width: 721px) {
    .logo {width: 82%;}
    .telefono, .language, .top_interna {display:none;}
    .top_pqts {height:700px;}
    .desc_pqts2 {height:400px;}
    .copy {display:none;}
}
@media screen and (max-width: 45.0625em){
  .page-head__menu-mobile {
      width: 12.66667%;
      float: left;
      padding-left: 9px;
      padding-right: 9px;
      margin-top: 10px;
  }
  .promo-box__grid {
    width: 100%;
    margin-left: 0px;
  }
}
.promo-box__title {
    background-color: rgba(0, 0, 0, 0);
}
