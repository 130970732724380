/*------------------------------------*\
    #LABEL
\*------------------------------------*/

label {
    color: $color-label;
    min-height: 1.5em;
    @include font-size(12px);
    @include font-weight(bold);
    transition: 0.2s;
    
    @include attention() {
        color: $color-label-hover;
        cursor: pointer;
    }
}