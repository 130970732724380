///*------------------------------------*\
//    #NAV-TOP
//\*------------------------------------*/

// Internal variables.
$logo-height:   108px;

.page-head {
    margin-bottom: $inuit-base-spacing-unit;
    box-shadow: inset 0px -96px 141px -42px rgba(0, 0, 0, 0.55);
    box-shadow: -10px -10px 10px 10px #aaaaaa;
}
.page-head__logo, .page-head__contact {
    margin: $inuit-base-spacing-unit--tiny 0;
}
.page-head__menu-mobile {
    @include media-query(palm) {
        @include span(1 of 6);
    }
}
.page-head__menu-mobile img {
    @include media-query(palm) {
        max-height: 140px;//$logo-height;
        padding: ($inuit-base-spacing-unit + 5px) 0;
        // aviatur logo: 522px, icon menu : 60px, 522/6 = 87, 87-60 = 27
        // 1 col of 6 for logo, 5 col of 6 for icon with 27 as padding-right
        // BUT work better with a padding of 38% jaja
        padding-right: 38%; 
    }
}
.page-head__logo {
    @include media-query(desk) {
        float: left;
    }
    @include media-query(portable) {
        max-width: 700px;
        margin: auto;
        text-align: center;
    }
    @include media-query(palm) {
        @include span(5 of 6);
    }
}
.page-head__aviatur {
    max-height: 110px;//$logo-height;
    padding: 0 0;
    float: left;
    @include media-query(portable) {
        float: none;
        content: url("../img/header/aviaturcolombia_logo-blanco.svg");
    }
}
.page-head__aviatur-pdf {
    max-height: 75px;
    padding: 5px 0;
}
.page-head__award {
    float: right;
    height: 100px;
    margin-left: 60px;
}
@media screen and (max-width: 1100px) {
    .page-head__award {
        display: none;
    }
}

.page-head__contact {
    padding: 14px 0;
    position: relative;
    @include media-query(desk) {
        float: right;
    }
}
.page-head__nbr-call {
    float: right;
    margin-left: 10px;
}
.page-head__icon-call {
    height: 70px;
    top: 15px;
    left: -80px;
    position: absolute;
}
.page-head__contact li {
    line-height: 20px; /*quarter($logo-height);*/

}





// PROMO TIME !
.page-head--promo {
    background: url(../img/header/cover.jpg) no-repeat center center;
    background-size: cover;
}
.page-head--promo--luna {
    background: none;
    position: relative;
}
.page-head--promo * {
    color: $color-page-head-promo;
}

.li-CointList {
    list-style: none;
}