///*------------------------------------*\
//    #PAGE-FOOT
//\*------------------------------------*/
.page-foot {
}
.page-foot__us {
    background-color: $color-page-foot-us;
    padding-top: $inuit-base-spacing-unit;
    padding-bottom: $inuit-base-spacing-unit;
}
.page-foot__us * {
    color: $color-page-foot-text;  
}
.page-foot__us a {
    @include link-quiet();
    @include font-size(13px);
}
.page-foot__us li {
    list-style-image: url(../img/footer/bullet.gif);
}
.page-foot__us__simple {
    @include span(1 of 4);
    @include media-query(portable) {
        @include span(1 of 3);
    }
}
.page-foot__us__simple ul {
    border-right: 1px solid $color-page-foot-text;
}
.page-foot__us__double {
    @include span(2 of 4 last);
    @include media-query(portable) {
        @include span(2 of 3);
    }
}
.page-foot__us__double div:first-of-type {
    @include span(1 of 2);
}
.page-foot__us__double  div:last-of-type {
    @include span(1 of 2);
}
.page-foot__social {
   //s background-color: $color-page-foot-social;
    color: $color-page-foot-text;
    text-align: center;
    padding-top: $inuit-base-spacing-unit;
}
.page-foot__social__icon {
    border: 1px solid $color-page-foot-text;
    border-radius: 30px;
    padding: $inuit-base-spacing-unit--tiny;
    margin: 0 $inuit-base-spacing-unit--tiny;
    @include link-quiet();
}
.page-foot__last {
    margin-bottom: $inuit-base-spacing-unit;
    padding-top: $inuit-base-spacing-unit;
}
.page-foot__legal {
    padding-right: $inuit-base-spacing-unit;
    @include font-size(11px);
}
.page-foot__p2p {
    margin-bottom: $inuit-base-spacing-unit;
    padding: 0;
    text-align: center;
}