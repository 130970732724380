///*------------------------------------*\
//    #COLORS
//\*------------------------------------*/

// Those colors are ONLY used in this SCSS
$color-brand:          #009bf8;

$color-main:           $color-brand;
$color-main-dark:      darken($color-brand, 8%);
$color-main-light:     lighten($color-brand, 8%);

$color-accent:         #fdc80e;
$color-accent-dark:    darken($color-accent, 8%);
$color-accent-light:   lighten($color-accent, 8%);

$color-luna:           #192F5E;

$color-positive:       #3f990f; // Green
$color-negative:       #C33A57; // Red
$color-highlight:      #fff;
$color-highlight-dark: darken($color-highlight, 10%);

$gray-base:            #000;
$gray-main-dark:       rgba($gray-base, 0.87); // e.g. Text
$gray-main-light:      rgba($gray-base, 0.54); // e.g. Secondary Text/Icons
$gray-accent-dark:     rgba($gray-base, 0.26); // e.g. Hint text
$gray-accent-light:    rgba($gray-base, 0.12); // e.g. Divider
$gray-tint:            rgba($gray-base, 0.017); // e.g. Light Background





// Page
$inuit-base-background-color:   #fff;
$inuit-base-text-color:         $gray-main-dark;





// Links
$color-links:           $color-main;
$color-links-disable:   $gray-main-light;
$color-links-quiet:     #fff;





// Type
$color-code:            $color-accent;
$color-pre-background:  $gray-main-dark;
$color-pre:             #fff;
$color-b-main:          $color-main;
$color-b-accent:        $color-accent;
$color-hr:              $gray-accent-light;





// Label
$color-label:           $gray-main-dark;
$color-label-hover:     darken($gray-main-dark, 10%);
$color-label-error:     $color-negative;





// Type component
$color-type-main:        $color-main;
$color-type-accent:      $color-accent-dark;
$color-type-negative:    $color-negative;
$color-type-grey:        $gray-main-light;





// Buttons
$color-btn:           $color-links;
$color-btn-accent:    $color-accent;
$color-btn-positive:  $color-positive;
$color-btn-negative:  $color-negative;
$color-btn-submit:    #e62d13;
$color-btn-inactive:  #e62d13;





// Frames
$color-frame-bg:           $gray-tint;
$color-frame-border:       $gray-accent-light;
$color-frame-hero:         $color-main;
$color-frame-hero-type:    #fff;
$color-frame-negative:     #C33A57;





// Modals
$color-modal-bg:           white;
$color-modal-border:       $gray-accent-dark;
$color-modal-head:         $color-main;
$color-modal-foot:         white;
$color-modal-head-color:   white;
$color-modal-backdrop-bg:  black;





// Modals
$color-nav-hover-bg:       $gray-tint;
$color-nav-border:         $gray-accent-light;
$color-nav-active:         $gray-main-light;
$color-nav-active-bg:      $gray-tint;
$color-nav-active-border:  $gray-accent-light;





// Forms
$color-form-border:         $gray-accent-light;
$color-form-underline:      $color-main;
$color-form-hightlight:     $color-main;
$color-form-readonly:       $gray-tint;





// Tables
$color-table-bg:            $gray-tint;
$color-table-border:        $gray-accent-light;





// Iconic
$color-icons:                   $gray-main-light;
$color-icons-hover:             $gray-main-dark;
$color-icons-light:             $gray-accent-dark;





// Datepicker
$color-datepicker:              $color-main;
$color-datepicker-border:       $gray-accent-light;




// Fixed
$color-fixed-border:            $gray-accent-light;





// Slidepanel
$color-slidepanel-border:       $gray-accent-light;





// Loading 
$color-loading-main:            $color-main;
$color-loading-negative:        $color-negative;





// Page head
$color-masthead:                #fff;
$color-page-head-border:        $color-main;
$color-page-head:               #fff;
$color-page-head-background:    #fff;
$color-page-head-promo:         #fff;





// Site main nav
$color-nav-main-item:           $color-main;
$color-nav-main-item--promo:    #fff;
$color-nav-main-luna:           $color-luna;




// Search Engine
$color-title-icon:           $color-main;





// Search Engine
$color-title-promo:          $color-main;
$color-box-promo:            #fff;





// Dispo
$color-dispo:                 $color-main;
$color-dispo-bg:              $gray-tint;
$color-dispo-border:          $gray-accent-light;
$color-dispo-icons:           $gray-main-light;
$color-dispo-selected:        $color-main;
$color-dispo-hover:           $color-accent;
$color-dispo-filter:          $color-accent-dark;





// Detail
$color-detail-main:             $color-main;
$color-detail-carousel-arrow:   $color-accent;
$color-detail-same-billing:     $gray-main-dark;





// Resume
$color-resume-border:         $gray-accent-dark;





// Page foot
$color-page-foot-text:        #fff;
$color-page-foot-us:          $color-main;
$color-page-foot-social:      $color-accent;





// Corporate
$color-corporate-blue:        $color-main;
$color-corporate-green:       #429d9e;






// Page micro
$color-page-micro:              #fff;
$color-page-micro-background:   $gray-main-dark;





// Headings
$color-headings:    #000;
$color-heading-sub: $gray-main-dark;





// Quotes
$color-quote:           $gray-main-light;
$color-quote--banner:   $gray-main-dark;





// Boxes
$color-box-highlight-background:    $color-highlight;
$color-box-highlight-border:        $gray-accent-dark;
$color-box-tint-background:         $gray-tint;





// Bands
$color-band-highlight-background:   $color-highlight;
$color-band-tint-background:        $gray-tint;
$color-band-dark:                   #fff;
$color-band-dark-background:        $gray-main-dark;