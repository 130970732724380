/*------------------------------------*\
    #FLOAT
\*------------------------------------*/
.clearfix {
    clear: both;
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}
.clearfix, .clearfix:after  {
    clear: both;
}


.pull-left {
    float: left !important;
}
.pull-right {
    float: right !important;
}


.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.inline-block {
    display: inline-block;
}


.relative {
    position: relative;
}


.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}