///*------------------------------------*\
//    #DETAIL-FLIGHT
//\*------------------------------------*/

.detail-flight__content {
    @include span(4 of 6);
    @include media-query(portable) {
        @include span(full);  
    }
}
.detail-flight__sidebar {
    @include media-query(desk) {
        @include span(2 of 6);
    }
}





.detail-flight__title {
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__label {
    padding: $inuit-base-spacing-unit--small 0 0;
}
.detail-flight__airline-icon {
    height: $inuit-base-spacing-unit--large;
    margin-left: $inuit-base-spacing-unit--small;
}
.detail-flight__trip {
    margin-bottom: $inuit-base-spacing-unit--tiny;
    padding: $inuit-base-spacing-unit--tiny 0;
    border-radius: $base-round;
}
.detail-flight__trip--bg {
    background-color: lighten($color-detail-main, 47%);
}
.detail-flight__col-city {
    @include span(2 of 5);
}
.detail-flight__col-icon {
    @include span(1 of 5);
}
.detail-flight__stopover {
    background-color: lighten($color-detail-main, 47%);
    padding-bottom: $inuit-base-spacing-unit;
}
.detail-flight__more {
    margin-bottom: $inuit-base-spacing-unit;
}





// Other detail sections
.detail-flight__rate-price,
.detail-flight__rate-detail,
.detail-flight__condition-title,
.detail-flight__condition-detail {
    @include span(1 of 2);
    margin-bottom: $inuit-base-spacing-unit;
}

.detail-flight__condition-title {
    margin-top: $inuit-base-spacing-unit--small;
}

.detail-flight__passenger-title {
    @include span(1 of 3);
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__passenger-detail {
    @include span(2 of 3);
    margin-bottom: $inuit-base-spacing-unit;
}
.detail-flight__passenger-detail__number {
    margin: 0 $inuit-base-spacing-unit--tiny;
}





// Itinerary map section
.map-container {
    width:100%;
}
.map-container h4 {
    text-align: center;
    margin-bottom: $inuit-base-spacing-unit--small;
    color: $color-main;
}
.map-container #map {
    height: 300px;
    margin-bottom: $inuit-base-spacing-unit;
}
.map-container .itinerary_flights {
    display: none;
}
.map-container #map .flight_marker {
    position: absolute;
    z-index: 999999;
}
.map-container #map .flight_marker .counter {
    position: absolute;
    top: 6.5px;
    left: 5.5px;
    width: 13px;
    line-height: 14px;
    text-align: center;
    font-weight: 900;
    font-size: 140%;
    font-family: 'Open Sans';
    color: #fbfbfb;
}
.map-container #map .flight_marker .counter img.multiCounts {
    position: absolute;
    top: 2px;
    left: 2px;
}
.map-container #map .flight_infoBox {
    padding: 5px 10px 5px 10px;
    font-family: 'Open Sans';
}
.map-container #map .flight_infoBox ~ .flight_infoBox  {
    border-top: 1px dashed #333;
}
.map-container #map .flight_infoBox .flight_stopover {
    font-style: italic;
}
.map-container #map .flight_infoBox .flight_placeName {
    font-weight: 900;
}