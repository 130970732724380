///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/


.search__title {
    float: left;
    color: #2c6eb0;
    font-weight: 900;
    @include font-size(30px);
    @include media-query(palm) {
        display: block;
        float: none;
        text-align: center;
        @include font-size(28px);
    }
}
.search__title__icon {
    font-size: quadruple($inuit-base-font-size);
    color: #2e6fb1;
}
.search__btn-group {
    float: right;
    margin: (halve($inuit-base-spacing-unit) + quarter($inuit-base-spacing-unit)) 0;
    border-radius: $inuit-btn-radius;
    @include media-query(palm) {
        float: none;
        display: table;
        text-align: center;
        left: calc( 50% - 230px);
        position: relative;
        width: 460px;
    }
}
.search__btn-group .btn {
    @include media-query(palm) {
        float: none;
    }
}





// - + Buttons for passengers
.search__btn-increment button {
    border-radius: 40px; //Very hight to get a circle
    margin-left: $inuit-base-spacing-unit--small;
    padding: $inuit-base-spacing-unit--small;
}
.search__btn-increment span {
    display: block;
    width: $inuit-base-spacing-unit;
}





// Style for span-link : - + trajet / Advanced options
.search__link-add-multi, .search__link-options {
    @include font-size(16px);
    @include font-weight(bold);
}
.search__link-add-multi {
    margin-top: $inuit-base-spacing-unit--small;
}
.search__link-options {
    @include media-query(lap-and-up) {
        position: relative;
        top: - $inuit-base-spacing-unit--small;
        text-align: right;
    }
}

.search-shared__datepicker-container {
    display: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: absolute;
    padding: 9px 9px 0 9px;
    width: 820px;
    z-index: $z-index-datepicker-container;
    box-shadow: 0px 10px 50px -17px rgba(0,0,0,0.75);

    @include media-query(portable) {
        width: calc(100% - 20px);
        max-width: 420px;
    }
}
/*.search-shared__datepicker-scroll {
    height: 100%;
}*/
/*.search-shared_datepicker-arrow-right {
    position: absolute;
    left: -24px;
    top: calc(50% - 20px);
    height: 40px;
    z-index: $z-index-datepicker-arrow;
}*/
.search-shared_datepicker-arrow-top {
    display: none;
    position: absolute;
    left: 30px;
    bottom: -15px;
    height: 20px;
    z-index: $z-index-datepicker-arrow;
}
@media screen and (min-width: 721px){
    .search__light--box{
        background-color: transparent;
        box-shadow: none;
    }
    .coinList {  
        position: absolute;      
        margin-top: 15px;
        margin-left: 8px;
    }  
    .dropbtn{
        color: #15173f !important;        
    }
    .search__light--box .btn-group > .btn--inactive{
        color: rgba(0, 0, 0, 0.54);
    }
    .search__light--box .search__option {
        color: rgb(33, 33, 33);
    }
}
@media screen and (max-width: 721px){   
    .li-CointList{
        display: none;
    }
}
@media screen and (min-width:722px) and (max-width:880px){
    .coinList {
        margin-top: 35px;
        margin-left: -77px;
    }
}
@media screen and (min-width:881px) and (max-width:1090px){
    .coinList {
        margin-top: 4px;
        margin-left: -107px;    
    }
}
.afa_search .text-input{
    border-radius: 20px;
}