/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

[role="button"] {
    outline: none;
}

.btn {
    transition: .2s;
    @include font-weight(bold);
    @include btn-color($color-btn);
    box-shadow: 0 0 2px rgba(0,0,0,.08),0 2px 4px rgba(0,0,0,.16);
    color: white;
    border-radius: 0;
    outline: none;
}

// Styling btn-group
.btn-group {
    display: inline-block;
    border-radius: $base-round;
    box-shadow: none;
    & > .btn {
        float:left;
        position: relative;
        border-color: $color-accent;
        box-shadow: none;
        @include attention() {
            z-index: $z-index-btn-group__btn-hover;
            box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
        &:not(.btn--inactive) {
            background-color: $color-accent;
            box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        }
    }
}


.btn--secondary {
    background: none;
    color: $color-btn;
    @include attention() {
        background: none;
        color: darken($color-btn, 10%);
    }
    @include attention(active) {
        background: $color-btn;
        color: white;
    }
}
.btn--accent {
    @include btn-color($color-btn-accent);
}
.btn--slim {
    @extend .btn;
    @extend .btn--small;
    font-size: .8em;
    font-weight: normal;
}
.btn--positive {
    @include btn-color($color-btn-positive);
}
.btn--negative {
    @include btn-color($color-btn-negative);
}
.btn--submit {
    @include font-weight(regular);
    @include btn-color($color-btn-submit);
}
.btn--inactive {
    @include btn-inactive($color-btn);
}
.btn--static {
    @include font-weight(regular);
    @include btn-static(#cfcfcf);
}


%btn-social {
    padding: 4px 12px;
}
.btn--facebook {
    @extend %btn-social;
    @include btn-color(#3b5998);
}
.btn--twitter {
    @extend %btn-social;
    @include btn-color(#1b95e0);
}
.btn--google {
    @extend %btn-social;
    @include btn-color(#db4437);
}


.buttonize {
    display: inline-block;
    padding: $inuit-base-spacing-unit--small;
    background-color: rgba($gray-base, .03);
    border: 1px solid rgba($gray-base, .06);
    border-bottom-width: 2px;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: rgba($gray-base, .06);
        border-bottom-color: rgba($gray-base, .09);
    }
    &:active, &.is-selected {
        background-color: lighten($color-brand, 46%);
        border-color: lighten($color-brand, 40%);
    }
}


.btn--scroll-top {
    display: none;
    width: 48px;
    height: 57px;
    position: fixed;
    bottom: -26px;
    right: $inuit-base-spacing-unit;
    padding: $inuit-base-spacing-unit--small;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    background-color: $color-main;
    box-shadow: 0 0 3px 1px rgba(64, 64, 64, 0.4);
    backface-visibility: hidden; // prevent repaint overhead on scroll event
}



/*------------------------------------*\
    #PILLS
\*------------------------------------*/
.pill {
    display: inline-block;
    &:not(:nth-child(1)) {
        margin-left: $inuit-base-spacing-unit--small;
    }
    & > .pill-height-helper {
        height: 100%;
    }
    & > * {
        vertical-align: middle;
    }
}
.btn-group > .btn:not(.btn--inactive), .btn-group > .btn--slim:not(.btn--inactive) {
    color: white;
}