///*------------------------------------*\
//    #SEARCH
//\*------------------------------------*/

.search__col-hotel-city {
    @include span(6 of 14);
    @include media-query(lap) {
        @include span(full);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
.search__col-hotel-date {
    @include span(4 of 14);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
}





.search-hotel__col-room {
    @include span(5 of 7);
    @include media-query(palm) {
        @include span(full);
        margin-bottom: $inuit-base-spacing-unit;
    }
}
.search-hotel__col-submit {
    @include span(2 of 7);
    @include media-query(palm) {
        @include span(full);
    }
}





.search-hotel__col-passenger {
    @include span(3 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
    }
    @include media-query(palm) {
        @include span(full);
    }
}
    .search-hotel__title-room {
        margin-bottom: $inuit-base-spacing-unit--small;
        margin-top: $inuit-base-spacing-unit--small;
        padding-bottom: $inuit-base-spacing-unit;
        padding-top: $inuit-base-spacing-unit;
        @include font-weight(bold);
        @include media-query(palm) {
            margin-top: 0;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
.search-hotel__col-child {
    @include span(2 of 15);
    @include media-query(lap) {
        @include span(4 of 12);
        float: right;
    }
    @include media-query(palm) {
        @include span(1 of 3);
    }
}