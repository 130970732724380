///*------------------------------------*\
//    #RESUME-FLIGHT
//\*------------------------------------*/

.resume-flight__col-large {
    @include span(3 of 7);
}
.resume-flight__col-small {
    @include span(1 of 7);
}






.resume-flight__airline-icon {
    height: $inuit-base-spacing-unit--large;
}