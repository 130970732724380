///*------------------------------------*\
//    #TYPES
//\*------------------------------------*/

.type-main {
    color: $color-type-main;
}
.type-accent {
    color: $color-type-accent;
}
.type-grey {
    color: $color-type-grey;
}
.type-negative {
    color: $color-type-negative;
}
.type-white {
    color: white;
}
.bold {
    @include font-weight(bold);
}
.error {
    color: red;
}