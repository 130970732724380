///*------------------------------------*\
//    #CORPORATE
//\*------------------------------------*/

.corporate__img-corner {
    position: absolute;
    left: -1px;
    top: -1px;
}
.corporate__title {
    margin-left: $inuit-base-spacing-unit--large;
    padding-left: $inuit-base-spacing-unit;
}





// WHITE CONTENT
.corporate__white {
    background-color: white;
    border: 1px solid #cdcdce;
    padding: 20px 25px;
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm){
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}

.corporate__col-bolivar {
    @include span(10 of 18);
    @include media-query(lap-and-up){
        margin-top: 80px;
    }
    @include media-query(palm){
        @include span(full)
    }
}

.corporate__col-login {
    @include span(full)
}

.corporate__col-description {
    @include span(1 of 2);
    @include media-query(palm){
        @include span(full)
    }
}





// COLORED BOXES
.corporate__description-content {
    color: white;
    padding: $inuit-base-spacing-unit;
    position: relative;
    @include media-query(lap){
        min-height: 530px;
    }
    @include media-query(desk){
        min-height: 365px;
    }
}
.corporate__description-arrow {
    left: calc(50% - #{$inuit-base-spacing-unit});
    position: absolute;
    top: -1px;
    width: $inuit-base-spacing-unit--large;
}
.corporate__description-title.corporate__green {
    color: $color-corporate-green;
}
.corporate__description-title.corporate__blue {
    color: $color-corporate-blue;
}
.corporate__description-content.corporate__green {
    background-color: $color-corporate-green;
}
.corporate__description-content.corporate__blue {
    background-color: $color-corporate-blue;
}
.corporate__description-content * {
    line-height: 1.5;
}





// VIDEO FOOTER
.corporate__video {
    margin-top: 30px;
    max-width: 500px;
}
.corporate__video-play:hover {
    background-color: #444;
    box-shadow: inset 0 0 10px #333;
}
.corporate__video-play:active {
    background-color: #333;
    box-shadow: inset 0 0 10px #222;
}
.corporate__video-arrow {
    height: 46px;
}
.corporate__video-play i {
    font-size: 50px;
}
.corporate__video-play p {
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
}
.corporate__video-btn {
    background-color: #555;
    color: white;
    display: inline-block;
    font-size:  25px;
    margin-top: 15px;
    padding: 10px 25px;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
}
.corporate__video-btn:hover {
    background-color: #444;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #333;
}
.corporate__video-btn:active, .corporate-video-btn:focus {
    background-color: #333;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #222;
}
.corporate__video-iframe {
    width: 100%;
    min-height: 300px;
}
.corporate__phone {
    margin-top: 30px;
    font-size: 25px;
}
.corporate-video-iframe {
  width: 100%;
  height: 500px;
}