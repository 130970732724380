///*------------------------------------*\
//    #JQUERY UI
//\*------------------------------------*/

// GLOBAL
.ui-state-default {
    padding: 2px;
}
.ui-state-clicked {
    background-color: rgba(255, 0, 0, .2);
}
.date-range-selected {
    background-color: $color-datepicker;
}
.date-range-selected a {
    color: #fff;
}





/*------------------------------------*\
    #DATEPICKER
\*------------------------------------*/

// Inline datepicker -- embedded in page instead of in an overlay, e.g. search page
#body-datepicker {
    margin: 0;
}
.ui-datepicker-inline {
    width: 100% !important;
    padding: 0;
    border: 0;
    box-shadow: none;
    & .ui-datepicker-prev, & .ui-datepicker-next {
        display: block;
    }
    & .ui-datepicker-buttonpane {
        display: none;
    }
    & .ui-datepicker-header .ui-datepicker-title {
        padding: 0;
        background-color: #fff;
    }
    & .ui-datepicker-calendar thead tr th,
    & .ui-datepicker-calendar tbody tr td {
        font-size: 1em;
    }
}
.ui-datepicker.ui-datepicker-modal {
    z-index: $z-index-datepicker-modal !important;
}
.ui-datepicker-group {
    padding: 10px 5px;
    width: 100%;
    max-width: 400px;
    display: inline-block;
    @include media-query(lap-and-up) {
        padding: 10px;
    }
}
.ui-datepicker-title {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}
select.ui-datepicker-month,
select.ui-datepicker-year {
    padding-left: 10px;
    @extend .select--arrow;
}
.ui-datepicker-next,
.ui-datepicker-prev {
    padding: 5px 20px;
    font-weight: 600;
    position: absolute;
    top: 0;
    @include attention() {
        background-color: $color-datepicker;
        color: #fff;
        box-shadow: 0 0 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .16);
    }
}
.ui-datepicker-next {
    right: 0;
    margin: $inuit-base-spacing-unit--small $inuit-base-spacing-unit 0 0;
}
.ui-datepicker-prev {
    left: 0;
    margin: $inuit-base-spacing-unit--small 0 0 $inuit-base-spacing-unit;
}
.ui-datepicker-prev.ui-state-disabled,
.ui-datepicker-next.ui-state-disabled {
    cursor: default;
    background-color: #fff;
    color: #aaa;
    box-shadow: none;
}
.ui-datepicker-current {
    display: none;
}
.ui-datepicker-calendar {
    border-collapse: collapse;
    width: 100%;
    max-width: 400px;
}
.ui-datepicker-calendar thead tr th {
    text-align: center;
}
.ui-datepicker-calendar tbody tr {
    height: 30px;
}
.ui-datepicker-calendar tbody tr td {
    position: relative;
    border: 1px solid #eee;
    text-align: center;
    height: 35px;
    @include media-query(lap-and-up) {
        height: 30px;
    }
}
.ui-datepicker-calendar tbody tr td.ui-datepicker-current-day {
    background-color: #e6f5ff;
}
.ui-datepicker-calendar tbody tr td span,
.ui-datepicker-calendar tbody tr td a {
    text-align: center;
}
.ui-datepicker-calendar tbody tr td .ui-state-default {
    background:none;
    border: none;
}
.ui-datepicker-calendar tbody tr td a.ui-state-default {
    display: block;
    color: $color-datepicker;
}
.ui-datepicker-calendar tbody tr td a.ui-price-default {
  display: block;
  color: #77da79;
  margin: 0px -10px 0px -10px;
  font-size: 9px;
}

.ui-datepicker-calendar tbody tr td a.ui-price-regular {
  display: block;
  color: #999;
  margin: 0px -10px 0px -10px;
  font-size: 9px;
}
.ui-datepicker-calendar tbody tr td[data-handler="selectDay"] {
    cursor: pointer;
    @include attention() {
        background-color: $color-datepicker;
        a {
            color: #fff;
        }
    }
    a {
        transition: color 0s;
    }
}
.ui-datepicker-calendar tbody tr td.depSelect {
    background-color: $color-datepicker;
}
.ui-datepicker-calendar tbody tr td.depSelect a {
    color: #fff;
}
.ui-datepicker-calendar tbody tr td.returnDate,
.ui-datepicker-calendar tbody tr td.depDate {
    background-color: $color-datepicker;
}
.ui-datepicker-calendar tbody tr td.returnDate a,
.ui-datepicker-calendar tbody tr td.depDate a,
.ui-datepicker-calendar tbody tr td.depDate span {
    color: #fff;
}
.ui-datepicker-calendar tbody tr td.period {
    animation: backgroundcolor .3s ease-out;
    background-color: #e6f5ff;
}
@include keyframes(backgroundcolor) {
    from    { background-color: transparent;}
    to      { background-color: #e6f5ff;}
}
.ui-datepicker-calendar tbody tr td span.arrow-corner {
    width: 0;
    height: 0;
    position: absolute;
}
.ui-datepicker-calendar tbody tr td span.corner-top-left {
    border-top: 0 solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid #e6f5ff;
    top: -1px;
    left: -1px;
    @include media-query(lap-and-up) {
        border-bottom-width: 18px;
        border-left-width: 15px;
    }
}
.ui-datepicker-calendar tbody tr td span.corner-top-right {
    border-top: 0 solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 10px solid #e6f5ff;
    top: -1px;
    right: -1px;
    @include media-query(lap-and-up) {
        border-bottom-width: 18px;
        border-right-width: 15px;
    }
}
.ui-datepicker-calendar tbody tr td span.corner-bottom-right {
    border-bottom: 0 solid transparent;
    border-top: 20px solid transparent;
    border-right: 10px solid #e6f5ff;
    right: -1px;
    bottom: -1px;
    @include media-query(lap-and-up) {
        border-top-width: 18px;
        border-right-width: 15px;
    }
}
.ui-datepicker-calendar tbody tr td span.corner-bottom-left {
    border-bottom: 0 solid transparent;
    border-top: 20px solid transparent;
    border-left: 10px solid #e6f5ff;
    left: -1px;
    bottom: -1px;
    @include media-query(lap-and-up) {
        border-top-width: 18px;
        border-left-width: 15px;
    }
}
.ui-datepicker-calendar tbody tr td span.point-bestPrice {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #77da79;
}
.ui-datepicker-unselectable span {
    color: #aaa;
    cursor: default;
}
@include media-query(palm) {
    .ui-datepicker-group-last {
        padding: 0;
    }
}




/*------------------------------------*\
    #AUTOCOMPLETE
\*------------------------------------*/

.ui-autocomplete {
    @extend %picker;
    width: calc(100% - 40px);
    max-height: $inuit-base-spacing-unit*25;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 4px;
}
.ui-helper-hidden-accessible {
    display:none;
}
.ui-autocomplete-category, .ui-menu-item  {
    list-style: none;
}
.ui-autocomplete-category {
    border-bottom: solid 1px #ededed;
    margin: 9px 0 0 0;
    padding: 0 0 4px 9px;
    color: $color-main;
    font-weight: bold;
}
.ui-menu-item {
    padding: 4px 0 3px 9px;
    @include media-query(palm) {
        line-height: 1.4;
    }
    &:not(.ui-autocomplete-category):not(.ui-disabled-state) {
        border-bottom: 1px solid transparent;
        cursor: pointer;
        @include attention() {
            background-color: #eee;
            border-bottom-color: #ddd;
        }
    }
    & > * {
        display: inline;
    }
}

.ui-menu-item-subitem {
    padding-left: 25px;
    font-style: italic;
}

.ui-autocomplete img.countryIco {
    margin: 0 2px 2px 0;
}
.ui-menu-item a {
    color: #444;
    font-size: 15px;
    @include media-query(lap-and-up) {
        font-size: 13px;
    }
}
.ui-menu-item a:hover {
    color: black;
}





/*------------------------------------*\
    #SLIDER
\*------------------------------------*/
/* Common */
.ui-slider {
    border: 1px solid #ddd;
    background-color: #fff;
    position: relative;
    text-align: left;
    border-radius: 2px;
}
.ui-slider-range {
    position: absolute;
    z-index: $z-index-slider-range;
    font-size: .7em;
    display: block;
    background-position: 0 0;
    background: #ddd;
    border: 1px solid #eee;
    color: #444;
}
.ui-slider-handle {
    position: absolute;
    z-index: $z-index-slider-handle;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    margin-left: -.6em;
    border: 1px solid #ddd;
    background: #f6f6f6;
    border-radius: 3px;
}
.ui-slider-handle:hover {
    border: 1px solid #0073ea;
    background: $color-main;
}
/* Horizontal slider */
.ui-slider.ui-slider-horizontal {
    height: .8em;
}
.ui-slider-horizontal .ui-slider-range {
    height: 100%;
}
.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
}
/* Vertical slider */
.ui-slider.ui-slider-vertical {
    width: .8em;
}
.ui-slider-vertical .ui-slider-range {
    width: 100%;
}
.ui-slider-vertical .ui-slider-handle {
    right: -.3em;
    margin-bottom: -.6em;
}
